import {Input,Form,FormItem,Radio,RadioGroup,Button,MessageBox} from 'element-ui';
import  ClipboardJS from 'clipboard';
import {getDetail} from '../api/publish';
import {showSuccessTip,showErrorTip} from  '../util/index.js';
import user from '../js/user.js';
import {deductCoin, getLikeInfo,like,favorite} from '../api/product-detail.js';
const iframeurl = '../scratch3/player.html';
export default {
  name:'home',
  components:{
    elInput:Input,
    elForm:Form,
    elFormItem:FormItem,
    elRadio:Radio,
    elRadioGroup:RadioGroup,
    elButton:Button
  },
  data(){
    this.productId = '';
    this.clipboard = new ClipboardJS('.copy',{
      text:function(){
        return location.href;
      }
    });
    
    return{
      disabled:true,
      productLoading:true,
      iframeurl,
      liked:false,
      favorited:false,
      info:{},
      authorInfo:{},
      ruleForm:{
        desc:'',
        remark:"",
        isCodeOpen:'',
      },  
    }
  },
  computed:{
    likeFill(){
    return this.liked === true ? '#1989fa' :'#606266';
    },
    eyeFill(){
    return '#1989fa';
    },
  },
  mounted(){
    this.productId = this.$route.query.id;
    this.iframeurl = `${iframeurl}?id=${this.productId}&from='otherpage'`
    this.getDetail();
    this.getLikeInfo();
    this.clipboard.on('success', function(e) {
      showSuccessTip('复制成功');
      e.clearSelection();
    });
    this.clipboard.on('error', function() {
      showErrorTip('复制失败');
    });
    this.$refs.product.onload = () => {
      this.productLoading = false;
    }
  },
  destroyed(){
    this.clipboard.destroy();
  },
  methods: {
    async getDetail(){
      const res = await getDetail({id:this.productId});
      if(res.error === null){
        const {data} = res;
        this.info = data;
        const {description='',open_source='',comment,author={}} = data;
        this.authorInfo =author
        this.ruleForm.desc = description;
        this.ruleForm.remark = comment;
        this.ruleForm.isCodeOpen = open_source;
      }
    },
    async getLikeInfo(){
      const res = await getLikeInfo({id:this.productId});
      if(res.error === null){
        const {data} = res;
        const {like=false,favorite=false} = data.history;
        this.liked = like;
        this.favorited = favorite;
      }
    },
    clicksee(){
      if(this.authorInfo.uid === user.userinfo.uid){
        window.open(`./scratch3/see.html?id=${this.productId}`);
        return;
      }
      if(user.isLogin === true){
        if(this.authorInfo.coin < 10){
          showErrorTip('您当前积分少于十分，还不能查看哦~')
        }else{
          MessageBox.confirm('进去看看将扣除十积分，是否继续？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const res = await deductCoin({
              id:this.productId,
              key:"get_code"
            });
            if(res.error === null){
              window.open(`./scratch3/see.html?id=${this.productId}`);
            }
          })
        }
      }else{
        user.login();
      }
    },
    async clickLike(){
      if(user.isLogin === false){
        user.login();
        return;
      }
      const action = this.liked === false ? true:false;
      await like({
        id:this.productId,
        action
      });
      this.getLikeInfo();
      this.getDetail();
    },
    async clickFavorite(){
      if(user.isLogin === false){
        user.login();
        return;
      }
      const action = this.favorited === false ? true : false;
      await favorite({
        id:this.productId,
        action
      });
      this.getLikeInfo();
      this.getDetail();
    }
  }
}