import {get,post} from '../util/request.js';
import {showErrorTip} from '../util/index.js'
// 扣除金币
const deductCoin = async (data) => {
  const res = await post({
    url:'/api/ProjectRelatedCoinAPI',
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

const getLikeInfo = async (data) => {
  const res = await get({
    url:'/api/ProjectRelatedCoinAPI',
    data
  });
  if(res.error){
    if(res.error !== 'login-required'){
      showErrorTip(res.data);
    }
  }
  return res;
};

const like = async (data) => {
  const res = await post({
    url:'/api/LikeProjectAPI',
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}
const favorite = async (data) => {
  const res = await post({
    url:'/api/FavoriteProjectAPI',
    data
  });
  if(res.error){
    showErrorTip(res.data);
  }
  return res;
}

export {
  deductCoin,
  getLikeInfo,
  like,
  favorite
}